import { graphql } from "gatsby";
import React from "react";
import { Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import config from "../../data/SiteConfig";
import PostListing from "../components/PostListing/PostListing";
import Layout from "../layout";
import * as S from "../styles/styles.css";

export default function CategoryTemplate({ pageContext, data }) {
  const { category } = pageContext;
  const postEdges = data.allMarkdownRemark.edges;
  return (
    <Layout title={category} location="categories">
      <S.GalleryRow>
        <Col>
          <S.GalleryRowInnerDiv className="listing-container">
          <div className="category-container">
            <Helmet title={`Posts in category "${category}" | ${config.siteTitle}`}/>
            <PostListing postEdges={postEdges} />
          </div>
          </S.GalleryRowInnerDiv>
        </Col>
      </S.GalleryRow>
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query CategoryPage($category: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
          }
        }
      }
    }
  }
`;
